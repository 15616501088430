<template>
  <footer class="text-light">
    <div class="bg-secondary">
      <div class="container">
        <div class="row mb-5 pt-5">
          <div class="col-12 col-lg-6 text-center text-lg-left mt-5">
            <img alt="DWG logo"  class="d-inline-block align-top img-fluid logo" src="@/assets/img/dwg-logo-transparent-with-strap.webp">
          </div>

          <div class="col-12 col-md-6 col-lg-3 text-center text-md-left mt-5">
            <h4 class="mb-4">Get in touch</h4>

            <ul>
              <li><a href="tel:+442073748061" class="text-white-50">UK: +44 (0)20 73748061</a></li>
              <li><a href="tel:+18669030232" class="text-white-50">US: +1 (866) 903 0232</a></li>
              <li><a href="mailto:info@digitalworkplacegroup.com" class="text-white-50">info@digitalworkplacegroup.com</a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-md-6 col-lg-3 text-center text-md-left mt-5">

          </div>
        </div>

        <div class="row copyright">
          <div class="col-12">
            <p class="small text-muted mb-3">DWG and Digital Workplace Group trading names of Digital Workplace Forum Group Limited UK (Company No. 02776450) and Digital Workplace Group USA Inc.</p>
          </div>

          <div class="col-12 legal">
            <div>
              <div class="text-white-50">
                <span class="d-block d-lg-inline mb-3">© 2022 Digital Workplace Forum Group.</span>
                <ul id="menu-legal-links" class="legal text-light">
                  <li>
                    <a href="/terms-conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-statement">Privacy Policy</a>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-model="showTermsModal"
        
        size="xl"
        scrollable
        cancel-disabled
        data-bs-backdrop="static"
        @ok="acceptTerms"
        @close="noDismiss"
        @hide="noDismiss"
      >
        <template #modal-header="">
          <div>
            <h2>User Agreement</h2>
            <p>Please accept the user agreement before continuing to use the extranet.</p>
          </div>
        </template>
        <template>
          <div class="row">
            <div class="col">
              <div>
                <p>A key aspect of DWG membership is confidentiality. On joining DWG, Member organisations have agreed to mutually share their digital workplace experiences openly to support peer to peer learning and keep that learning within their own organisations and the membership community. All content on this extranet must be treated the same way.<br /><br />By checking this box, you are accepting the <a target="_blank" href="/terms-conditions">Membership Terms of Use</a> and the <a target="_blank" href="/privacy-statement">Privacy Statement</a></p>
                <hr />
                <h4>Registrations and authorisations</h4>
                <p>Our VAT number is 606-0744-62</p>
                <hr />
                <h4>Our details</h4>
                <p>The full name of our company is Digital Workplace Forum Group Limited T/A Digital Workplace Group.</p>
                <p>We are registered under registration number 2776450 England &amp; Wales</p>
                <p>Our address is 2 Leman Street, London, E1W 9US</p>
                <p>You can contact us by email to&nbsp;<a class="email" href="mailto:admin@digitalworkplacegroup.com" target="_blank" rel="noopener">admin@digitalworkplacegroup.com</a></p>
              </div>
            </div>
          </div>

        </template>
        <template #modal-footer="{ ok }">
          <div class="row mt-4">
            <b-form-checkbox
                id="agreeTerms"
                v-model="termsOptIn"
                name="agreeTerms"
                value="1"
                unchecked_value="0"
                class="mr-5 mt-2"
                @change="allowContinue"
            >
              I accept these terms
            </b-form-checkbox>

            <b-button @click="ok()" :disabled="continueDisabled">Continue</b-button>

          </div>
        </template>
      </b-modal>
    </div>
  </footer>

</template>

<script>
import store from "@/store";
import router from "@/router";
import jwt from '@/auth/jwt/useJwt'

export default {
  name: 'PageFooter',
  data() {
    return {
      termsOptIn: 0,
      continueDisabled: true,
      acceptedAgreement: false,
      showTermsModal: false,
    }
  },
  mounted() {
    this.checkUserTerm()
  },
  methods: {
    noDismiss(e) {
      if(!this.acceptedAgreement) e.preventDefault()
    },
    allowContinue() {
      this.continueDisabled = !this.termsOptIn
    },
    checkUserTerm() {
      jwt.getUserData() && store.dispatch('app/checkUserAgreement')
        .then()
        .catch(error => {
          if (error.response.status === 404) {
            this.showTermsModal = true;
            if (router.currentRoute.name === 'Terms & Conditions') this.showTermsModal = false;
            if (router.currentRoute.name === 'Privacy Statement') this.showTermsModal = false;
          }
        })
    },
    acceptTerms() {
      store.dispatch('app/acceptAgreement').then().catch()
      this.acceptedAgreement = true
      this.showTermsModal = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>


