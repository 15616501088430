import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import jwt from '@/auth/jwt/useJwt'



Vue.use(VueRouter)

const routes = [
  {
    path: '/category-images',
    name: 'category_images',
    component: () => import('@/views/CategoryImagesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Category Images',
      breadcrumb: [
        {
          text: 'Category Images',
          active: true,
        },
      ],
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  { 
    path: '/default.aspx', 
    redirect: { 
      name: 'home' 
    } 
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      pageTitle: 'Login',
      breadcrumb: [
        {
          text: 'Login',
          active: true,
        },
      ],
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Logout',
      breadcrumb: [
        {
          text: 'Logout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members/public_profile.asp',
    name: 'member_legacy',
    redirect: to => {
      return { path: '/members/'+to.query.id, query: null }
    },
    meta: {
      authRequired: true,
      pageTitle: 'Member redirect',
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/members/MembersView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Members',
      breadcrumb: [
        {
          text: 'Members',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members/:id',
    name: 'member',
    component: () => import('@/views/members/MemberView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Organisations',
          to: { name: 'members' },
        },
        {
          text: 'Organisation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members/:id/content',
    name: 'member-content',
    component: () => import('@/views/members/MemberContentView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Members',
          to: { name: 'members' },
        },
        {
          text: 'Member',
          to: { name: 'members' },
        },
        {
          text: 'Content',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members/:id/content/page/:page',
    name: 'member-content-paged',
    component: () => import('@/views/members/MemberContentView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Members',
          to: { name: 'members' },
        },
        {
          text: 'Member',
          to: { name: 'members' },
        },
        {
          text: 'Content',
          active: true,
        },
      ],
    },
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/staff/TeamView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Meet the team',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/:id',
    name: 'staff',
    component: () => import('@/views/staff/StaffView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Staff',
      breadcrumb: [
        {
          text: 'Staff',
        },
        {
          text: 'Staff',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/ProfileView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/research-library',
    name: 'reports',
    component: () => import('@/views/reports/ReportsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Research library',
      breadcrumb: [
        {
          text: 'Research library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/research-library/page/:page',
    name: 'reports-paged',
    component: () => import('@/views/reports/ReportsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Research library',
      breadcrumb: [
        {
          text: 'Research library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/research-library/:slug',
    name: 'report',
    component: () => import('@/views/reports/ReportView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Research library',
          to: { name: 'reports' },
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/files/:slug',
    name: 'file',
    component: () => import('@/views/files/FileView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'File',
      breadcrumb: [
        {
          text: 'Files',
          to: { name: 'files' },
        },
        {
          text: 'File',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('@/views/events/EventsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/future',
    name: 'future-events',
    component: () => import('@/views/events/FutureEventsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Future Events',
      breadcrumb: [
        {
          text: 'Future Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/future/page/:page',
    name: 'future-events-paged',
    component: () => import('@/views/events/FutureEventsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Future Events',
      breadcrumb: [
        {
          text: 'Future Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/past',
    name: 'past-events',
    component: () => import('@/views/events/PastEventsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Past events',
      breadcrumb: [
        {
          text: 'Past events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/past/page/:page',
    name: 'past-events-paged',
    component: () => import('@/views/events/PastEventsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Past events',
      breadcrumb: [
        {
          text: 'Past events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/:slug',
    name: 'event',
    component: () => import('@/views/events/EventView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'events' },
        },
        {
          text: 'Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/NewsArticlesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/page/:page',
    name: 'news-paged',
    component: () => import('@/views/news/NewsArticlesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/:slug',
    name: 'news-article',
    component: () => import('@/views/news/NewsArticleView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Article',
      breadcrumb: [
        {
          text: 'News',
          to: { name: 'news' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/blogpost/439672/:slug/:notused',
    name: 'news-article-legacy',
    component: () => import('@/views/news/NewsArticleView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Article',
      breadcrumb: [
        {
          text: 'News',
          to: { name: 'news' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/research-blog/:slug',
    name: 'research-blog-article',
    redirect: to => {
      const reports = require('@/data/research-blog.json')
      if(reports[to.params.slug] === undefined) return { path: '/error', query: null }
      window.location.href = '/research-library/'+reports[to.params.slug]
    },
    component: () => import('@/views/research-blogs/ResearchBlogView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Research blog',
      breadcrumb: [
        {
          text: 'Research blog',
          to: { name: 'research-blog' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
    // following block needs updating to use a JSON look up file, same as above. IT needs to take the legacy slug, and redirect to PDF report UUID
  {
    path: '/blogpost/445048/:slug/:notused',
    name: 'research-blog-article-legacy',
    component: () => import('@/views/research-blogs/ResearchBlogView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Research blog',
      breadcrumb: [
        {
          text: 'Research blog',
          to: { name: 'research-blog' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/case-studies',
    name: 'case-studies',
    component: () => import('@/views/good-practice-kb/GoodPracticeKBsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Case studies knowledge base',
      breadcrumb: [
        {
          text: 'Case studies knowledge base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/case-studies/page/:page',
    name: 'case-studies-paged',
    component: () => import('@/views/good-practice-kb/GoodPracticeKBsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Case studies knowledge base',
      breadcrumb: [
        {
          text: 'Case studies knowledge base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/case-study/:slug',
    name: 'case-study',
    component: () => import('@/views/good-practice-kb/GoodPracticeKBView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Case studies knowledge base',
      breadcrumb: [
        {
          text: 'Case studies knowledge base',
          to: { name: 'case-study' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/blogpost/553709/:slug/:notused',
    name: 'case-study-lagacy',
    component: () => import('@/views/good-practice-kb/GoodPracticeKBView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Case studies knowledge base',
      breadcrumb: [
        {
          text: 'Case studies knowledge base',
          to: { name: 'case-study' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ask-dwg',
    name: 'ask-dwg',
    component: () => import('@/views/ask-dwg/AskDwgArticlesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'AskDWG',
      breadcrumb: [
        {
          text: 'AskDWG',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ask-dwg/page/:page',
    name: 'ask-dwg-paged',
    component: () => import('@/views/ask-dwg/AskDwgArticlesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'AskDWG',
      breadcrumb: [
        {
          text: 'AskDWG',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ask-dwg/:slug',
    name: 'ask-dwg-article',
    component: () => import('@/views/ask-dwg/AskDwgArticleView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Article',
      breadcrumb: [
        {
          text: 'AskDWG',
          to: { name: 'ask-dwg' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/blogpost/1735120/:slug/:notused',
    name: 'ask-dwg-article-legacy',
    component: () => import('@/views/ask-dwg/AskDwgArticleView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Article',
      breadcrumb: [
        {
          text: 'AskDWG',
          to: { name: 'ask-dwg' },
        },
        {
          text: 'Article',
          active: true,
        },
      ],
    },
  },
  {
    path: '/galleries',
    name: 'galleries',
    component: () => import('@/views/galleries/GalleriesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Galleries',
      breadcrumb: [
        {
          text: 'Galleries',
          active: true,
        },
      ],
    },
  },
  {
    path: '/galleries/page/:page',
    name: 'galleries-paged',
    component: () => import('@/views/galleries/GalleriesView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Galleries',
      breadcrumb: [
        {
          text: 'Galleries',
          active: true,
        },
      ],
    },
  },
  {
    path: '/galleries/:slug',
    name: 'gallery',
    component: () => import('@/views/galleries/GalleryView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Gallery',
      breadcrumb: [
        {
          text: 'Galleries',
          to: { name: 'galleries' },
        },
        {
          text: 'Gallery',
          active: true,
        },
      ],
    },
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('@/views/videos/VideosView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Videos',
      breadcrumb: [
        {
          text: 'Videos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/videos/page/:page',
    name: 'videos-paged',
    component: () => import('@/views/videos/VideosView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Videos',
      breadcrumb: [
        {
          text: 'Videos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/videos/:slug',
    name: 'video',
    component: () => import('@/views/videos/VideoView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Video',
      breadcrumb: [
        {
          text: 'Videos',
          to: { name: 'videos' },
        },
        {
          text: 'Video',
          active: true,
        },
      ],
    },
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import('@/views/topics/TopicsView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Topics',
      breadcrumb: [
        {
          text: 'Topics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/topics/:slug',
    name: 'topic',
    component: () => import('@/views/topics/TopicView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Topic',
      breadcrumb: [
        {
          text: 'Topics',
          to: { name: 'topics' },
        },
        {
          text: 'Topic',
          active: true,
        },
      ],
    },
  },

  {
    path: '/ask-dwg-intro',
    name: 'ask-dwg-intro',
    component: () => import('@/views/ask-dwg/AskDwgIntroView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'AskDWG',
      breadcrumb: [
        {
          text: 'AskDWG',
          active: true,
        },
      ],
    },
  },


  {
    path: '/tech-programme',
    name: 'tech-programme',
    component: () => import('@/views/tech-programme/OverviewView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Tech Programme',
      breadcrumb: [
        {
          text: 'Tech Programme',
          active: true,
        },
      ],
    },
  },

  {
    path: '/terms-conditions',
    name: 'Terms & Conditions',
    component: () => import('@/views/TermsView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Terms & Conditions',
    },
  },
  {
    path: '/privacy-statement',
    name: 'Privacy Statement',
    component: () => import('@/views/PrivacyView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Privacy Statement',
    },
  },


  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/SearchView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Search',
    },
  },
  {
    path: '/error.html',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Error',
    },
  },
  {
    path: '/error.html?message=:message&error=:error',
    name: 'error-msg',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Error',
    },
  },
  {
    path: '/forbidden?message=:message',
    name: 'forbidden',
    component: () => import('@/views/ForbiddenView.vue'),
    meta: {
      authRequired: false,
      pageTitle: 'Forbidden',
    },
  },
  {
    path: '/global_engine/download.aspx',
    name: 'global_download',
    redirect: to => {
      const downloads = require('@/data/global_downloads.json')
      if(downloads[to.query.fileid] === undefined) return { path: '/error', query: null }
      window.location.href = '/resource/collection/'+downloads[to.query.fileid]
      //return { path: '/resource/collection/'+downloads[to.query.fileid], query: null }
    },
    meta: {
      authRequired: true,
      pageTitle: 'Download',
    },
  },
  {
    path: '/link.asp',
    name: 'ymlink',
    redirect: to => {
      const ymlinks = require('@/data/ymlinks.json')
      if(ymlinks[to.query.ymlink] === undefined) return { path: '/error', query: null }
      window.location.href = ymlinks[to.query.ymlink]
      //return { path: ymlinks[to.query.ymlink], query: null }
    },
    meta: {
      authRequired: true,
      pageTitle: 'Download',
    },
  },
  {
    path: '/extranet/:slug',
    name: 'wp-page',
    component: () => import('@/views/pages/ExtranetPageView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Page',
    },
  },
  {
    path: '/:slug',
    name: 'wp-public-page',
    component: () => import('@/views/pages/PublicPageView.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Page',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  // If auth is required and the user is logged in...
  if(isUserLoggedIn())
    next()
  else
    redirectToLogin()



  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
})

/**
 * Note to developer: 
 * This will ensure the user local storage is always updated once a route is parsed / changed.
 * There is a chance this will too late to update the user data, if the request to /user/me is too slow 
 * but the API will not return the data if permissions are changed ect.
 * 
 * This "user not being updated in time" issue will only be an issue for 1 page load. Once the user refreshes or re-navigates to the page, the user data will be updated.
 * 
 */ 
router.afterEach((to, from) => {
  if(isUserLoggedIn()) jwt.updateUserData();
})

export default router
