<template>
  <div class="bg-light">
    <header id="page-header" class="">
      <b-navbar toggleable="lg" type="dark" fixed="top" variant="dark">
        <div class="container">

          <b-navbar-brand :to="{ name: 'home', params: {} }" class="mr-5 d-flex">
            <img src="/img/logo-beta.png" alt="DWG" class=""/>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'news', params: {} }">News</b-nav-item>
              <b-nav-item-dropdown text="Events">
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'overview-for-events'} }">Overview</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'future-events', params: {} }">Upcoming events</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'past-events', params: {} }">Past events</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'member-hosting-guide'} }">Host a member meeting</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="Members">
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'membership-benefits'} }">Benefits</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'members', params: {} }">Directory</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'membership-survey-results'} }">Survey</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'new-to-dwg'} }">New to DWG?</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="Knowledge base">
                <b-dropdown-item :to="{ name: 'ask-dwg-intro', params: { } }">AskDWG</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'case-studies', params: {} }">Case studies</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'search', query: { 'refinementList[category][]': 'Podcast' } }">Podcasts</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'reports', params: {} }">Research</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'topics', params: {} }">Resources by topic</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'galleries', params: {} }">Screenshots</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'videos', params: {} }">Videos</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="Consulting">
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'consulting'} }">Overview</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'collaboration-social-consult'} }">Collaboration &amp; social consulting</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'consulting-team'} }">Consulting team</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'digital-workplace'} }">Digital workplace consulting</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'intranet-consulting'} }">Intranet consulting</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'research-driven'} }">Research-driven</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="Benchmarking">
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'benchmarking-overview'} }">Overview</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'benchmark-league-tables'} }">League tables</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'wp-page', params: { slug: 'benchmark-sample-reports'} }">Sample reports</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item :to="{ name: 'tech-programme', params: {} }">Tech programme</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">


              <b-nav-item v-b-modal.modal-search class="d-flex align-items-center mr-lg-2 text-white-50">
                <span class="d-md-none">Search</span>
                <b-icon-search class="d-none d-lg-inline-block"></b-icon-search>
              </b-nav-item>

              <b-nav-item-dropdown right v-if="userData">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <b-avatar
                      :src="userData.icon_url"
                      :text="avatarText(userData.name)"
                      size="2rem"
                      circle
                      class="img-fluid"
                  />
                </template>
                <div v-if="userData">
                  <b-dropdown-item
                    :to="{ name : 'profile' }"
                  >
                    Update Profile
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name : 'member', params: { id: userData.organisation ? userData.organisation.id : '' } }"
                  >
                    {{ userData.organisation ? userData.organisation.name : '' }}
                  </b-dropdown-item>
                  <b-dropdown-item href="#">Time zone: {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</b-dropdown-item>
                  <b-dropdown-item v-if="userData.role === 'admin'" :href="adminUrl">Admin</b-dropdown-item>
                  <b-dropdown-item v-if="userData.role === 'manager'" :href="adminUrl">Organisation management</b-dropdown-item>
                  <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
                </div>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </header>

  </div>
</template>

<script>
import jwt from '@/auth/jwt/useJwt'
import {ref} from "vue";
import router from '@/router';
import {BAvatar} from "bootstrap-vue";
import {avatarText} from "@/@core/utils/filter";

export default {
    name: "PageHeader",
    components: {BAvatar},
  setup() {
    const userData = ref({})
    userData.value = jwt.getUserData()
    const logOutUrl = ref({})
    logOutUrl.value = jwt.getLogoutUrl()
    const adminUrl = process.env.VUE_APP_ADMIN_URL
    return {
        userData,
      logOutUrl,
      avatarText,
      adminUrl,

    }
  },
  methods: {
    logout() {
      const logoutUrl = jwt.getLogoutUrl();
      jwt.clearUserData();
      window.location.href = logoutUrl;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>


